import { call, takeLatest, put } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { OrganizationTabsActions } from 'store/actions';
import { OrganizationTabsApi } from 'store/apis';

import { IGetOrganizationAction } from 'types/interfaces';

// ---------- Workers ----------
// general
function* getOrganizationTabsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(OrganizationTabsApi.getOrganizationTabs, payload.organizationId);

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationTabs.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationTabs.success({
                    organizationTabs: data,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationTabs.failure(e));
    }
}

// delegation insights
function* getOrganizationActiveDelegatesWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationActiveDelegates,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationActiveDelegates.failure({ error }));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationActiveDelegates.success({
                    organizationActiveDelegates: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationActiveDelegates.failure(e));
    }
}

function* getOrganizationTopDelegatesWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationTopDelegates,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationTopDelegates.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationTopDelegates.success({
                    organizationTopDelegates: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationTopDelegates.failure(e));
    }
}

// voters insights
function* getOrganizationProposalsTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationProposalsTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationProposalsTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationProposalsTrends.success({
                    organizationProposalsTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationProposalsTrends.failure(e));
    }
}

function* getOrganizationSuccessfulProposalsTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationSuccessfulProposalsTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationSuccessfulProposalsTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationSuccessfulProposalsTrends.success({
                    organizationSuccessfulProposalsTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationSuccessfulProposalsTrends.failure(e));
    }
}

function* getOrganizationVotesTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationVotesTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationVotesTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationVotesTrends.success({
                    organizationVotesTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationVotesTrends.failure(e));
    }
}

function* getOrganizationVotersTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationVotersTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationVotersTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationVotersTrends.success({
                    organizationVotersTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationVotersTrends.failure(e));
    }
}

function* getOrganizationAverageVotesPerProposalTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationAverageVotesPerProposalTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationAverageVotesPerProposalTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationAverageVotesPerProposalTrends.success({
                    organizationAverageVotesPerProposalTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationAverageVotesPerProposalTrends.failure(e));
    }
}

// ---------- SAGA ----------
export const organizationTabsSaga = function* (): SagaIterator {
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_TABS, getOrganizationTabsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_ACTIVE_DELEGATES, getOrganizationActiveDelegatesWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_TOP_DELEGATES, getOrganizationTopDelegatesWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_PROPOSALS_TRENDS, getOrganizationProposalsTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS, getOrganizationSuccessfulProposalsTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_VOTES_TRENDS, getOrganizationVotesTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_VOTERS_TRENDS, getOrganizationVotersTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS, getOrganizationAverageVotesPerProposalTrendsWorker);
};
