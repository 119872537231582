import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components/index';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { TableHeader } from '../TableHeader';
import { TableRow } from '../TableRow';

import { useAppSelector } from 'store';
import { FormatUtils } from 'utils';

export const VotingActivityTable: FC = (): ReactElement => {
    const {
        organizationActivityData: {
            proposalActivity: {
                proposalParticipation,
                proposalsCount,
                proposersCount,
                successfulProposalsPercentage,
                voterParticipation,
            },
            voteActivity: { avgVotesPerProposal, votersCount, votesCount },
        },
    } = useAppSelector(({ organization }) => organization);

    const totalVotes = FormatUtils.formatExistingNumber(votesCount, 0);
    const totalVoters = FormatUtils.formatExistingNumber(votersCount, 0);
    const votesPerProposal = !FormatUtils.checkIfDataNotExist(avgVotesPerProposal)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(avgVotesPerProposal as number), 0)
        : DATA_NOT_FOUND;

    const totalProposals = FormatUtils.formatExistingNumber(proposalsCount, 0);
    const totalProposers = FormatUtils.formatExistingNumber(proposersCount, 0);
    const formattedSuccessfulProposalsPercentage =
        FormatUtils.formatExistingNumber(successfulProposalsPercentage, 1) + '%';
    const proposalParticipationCount = !FormatUtils.checkIfDataNotExist(proposalParticipation)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(proposalParticipation as number), 1)
        : DATA_NOT_FOUND;
    const voterParticipationCount = !FormatUtils.checkIfDataNotExist(voterParticipation)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(voterParticipation as number), 1)
        : DATA_NOT_FOUND;

    return (
        <>
            <TableHeader>
                <div className={styles.proposals}>proposals</div>
                <div className={styles.creators}>proposals creators</div>
                <div className={styles.successful}>successful</div>
                <div className={styles.participation}>
                    participation %
                    <div className={styles.participationInfo}>
                        <span>proposal</span>
                        <span>voter</span>
                    </div>
                </div>
                <div className={styles.totalVotes}>total votes</div>
                <div className={styles.voters}>voters</div>
                <div className={styles.avgVotes}>avg. votes per proposal</div>
            </TableHeader>
            <TableRow>
                <div className={styles.proposals}>{totalProposals}</div>
                <div className={styles.creators}>{totalProposers}</div>
                <div className={styles.successful}>{formattedSuccessfulProposalsPercentage}</div>
                <div className={styles.participationProposal}>
                    <TooltipWrapper title={proposalParticipationCount}>
                        <span>{proposalParticipationCount}</span>
                    </TooltipWrapper>
                </div>
                <div className={styles.participationVoter}>
                    <TooltipWrapper title={voterParticipationCount}>
                        <span>{voterParticipationCount}</span>
                    </TooltipWrapper>
                </div>
                <div className={styles.totalVotes}>{totalVotes}</div>
                <div className={styles.voters}>{totalVoters}</div>
                <div className={styles.avgVotes}>
                    <TooltipWrapper title={votesPerProposal}>
                        <span>{votesPerProposal}</span>
                    </TooltipWrapper>
                </div>
            </TableRow>
        </>
    );
};

/*
*
* const {
        organizationActivityData: {
            voteActivity: { avgVotesPerProposal, votersCount, votesCount },
        },
    } = useAppSelector(({ organization }) => organization);

    const totalVotes = FormatUtils.formatExistingNumber(votesCount, 0);
    const totalVoters = FormatUtils.formatExistingNumber(votersCount, 0);
    const votesPerProposal = !FormatUtils.checkIfDataNotExist(avgVotesPerProposal)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(avgVotesPerProposal as number), 0)
        : DATA_NOT_FOUND;

    return (
        <>
            <TableHeader>
                <div className={styles.totalVotes}>total votes</div>
                <div className={styles.voters}>voters</div>
                <div className={styles.avgVotes}>avg. votes per proposal</div>
            </TableHeader>
            <CurrentTableRow>
                <div className={styles.totalVotes}>{totalVotes}</div>
                <div className={styles.voters}>{totalVoters}</div>
                <div className={styles.avgVotes}>
                    <TooltipWrapper title={votesPerProposal}>
                        <span>{votesPerProposal}</span>
                    </TooltipWrapper>
                </div>
            </CurrentTableRow>
        </>
    );*/
