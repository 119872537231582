import {
    COMMON_TABLE_FIELD_TYPES,
    ICommonTableHeader,
    ICommonTableHeaderSettings,
} from '../../../../../../CommonTable/types';
import {
    IOrganizationTopDelegate,
    ITopDelegatesDelegateAddress,
    ITopDelegatesEnsName,
    ITopDelegatesNumberOfDelegators,
    ITopDelegatesTotalVotes,
    ITopDelegatesVotes1Year,
    ITopDelegatesVotes3Months,
    ITopDelegatesVotes6Months,
    ITopDelegatesVotingPower,
} from '../../../../../../../types/interfaces';

export enum ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS {
    DELEGATE_ADDRESS = 'delegateAddress',
    ENS_NAME = 'ensName',
    VOTING_POWER = 'votingPower',
    NUMBER_OF_DELEGATORS = 'numberOfDelegators',
    TOTAL_VOTES = 'totalVotes',
    VOTES_1YEAR = 'votes1Year',
    VOTES_3MONTHS = 'votes3Months',
    VOTES_6MONTHS = 'votes6Months',
}

const tableSettingsEightItems: ICommonTableHeaderSettings = {
    width: {
        desktop: '12.5%',
    },
};

const DELEGATE_ADDRESS: ICommonTableHeader<ITopDelegatesDelegateAddress, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Delegate Address',
    rowFieldKey: 'delegateAddress',
    fieldType: COMMON_TABLE_FIELD_TYPES.INNER_LINK,
    settings: tableSettingsEightItems,
};

const ENS_NAME: ICommonTableHeader<ITopDelegatesEnsName, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'ENS',
    rowFieldKey: 'ensName',
    fieldType: COMMON_TABLE_FIELD_TYPES.INNER_LINK,
    // sortedParamName: DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.ORGANIZATIONS_COUNT,
    settings: tableSettingsEightItems,
};

const VOTING_POWER: ICommonTableHeader<ITopDelegatesVotingPower, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Voting Power',
    rowFieldKey: 'votingPower',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTING_POWER,
    settings: tableSettingsEightItems,
};

const NUMBER_OF_DELEGATORS: ICommonTableHeader<
    ITopDelegatesNumberOfDelegators,
    ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS
> = {
    title: 'Delegators',
    rowFieldKey: 'numberOfDelegators',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.NUMBER_OF_DELEGATORS,
    settings: tableSettingsEightItems,
};

const TOTAL_VOTES: ICommonTableHeader<ITopDelegatesTotalVotes, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Total votes',
    rowFieldKey: 'totalVotes',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.TOTAL_VOTES,
    settings: tableSettingsEightItems,
};

const VOTES_1YEAR: ICommonTableHeader<ITopDelegatesVotes1Year, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Votes 1 Year',
    rowFieldKey: 'votes1Year',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTES_1YEAR,
    settings: tableSettingsEightItems,
};

const VOTES_6MONTHS: ICommonTableHeader<ITopDelegatesVotes6Months, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Votes 6 Months',
    rowFieldKey: 'votes6Months',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTES_6MONTHS,
    settings: tableSettingsEightItems,
};

const VOTES_3MONTHS: ICommonTableHeader<ITopDelegatesVotes3Months, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Votes 3 Months',
    rowFieldKey: 'votes3Months',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTES_3MONTHS,
    settings: tableSettingsEightItems,
};

const addSettingsToTableHeaderData = <T>(
    data: ICommonTableHeader<T, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS>[],
    settings: ICommonTableHeaderSettings,
): ICommonTableHeader<T, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS>[] =>
    data.map((item) => ({
        ...item,
        settings,
    }));

const organizationTopDelegatesTableHeaders: ICommonTableHeader<
    IOrganizationTopDelegate,
    ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS
>[] = addSettingsToTableHeaderData(
    [
        DELEGATE_ADDRESS,
        ENS_NAME,
        VOTING_POWER,
        NUMBER_OF_DELEGATORS,
        TOTAL_VOTES,
        VOTES_1YEAR,
        VOTES_3MONTHS,
        VOTES_6MONTHS,
    ] as ICommonTableHeader<IOrganizationTopDelegate, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS>[],
    tableSettingsEightItems,
);

export { organizationTopDelegatesTableHeaders };
