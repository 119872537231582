import React, { FC, ReactElement, useEffect } from 'react';

import styles from './styles.module.scss';

import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { DELEGATION_INSIGHTS } from '../../../../../../constants/organizationTabs';
import { PremiumTab } from '../../../../../PremiumTab';
import { OrganizationTabsActions } from 'store/actions';
import { CommonTable } from 'components';
import {
    ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS,
    organizationActiveDelegatesTableHeaders,
} from './constants/organization-active-delegates.constants';
import {
    ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS,
    organizationTopDelegatesTableHeaders,
} from './constants/organization-top-delegates.constants';
import { LinkUtils } from '../../../../../../utils';

export const OrganizationDelegationInsights: FC = (): ReactElement => {
    const { organizationTabs, organizationActiveDelegates, organizationTopDelegates } = useAppSelector(
        ({ organizationTabs }) => organizationTabs,
    );
    const isTabBlocked = organizationTabs.data.find((t) => t.tab.name === DELEGATION_INSIGHTS)?.isBlocked;
    const { organizationData } = useAppSelector(({ organization }) => organization);
    const organizationId = organizationData.id;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(OrganizationTabsActions.getOrganizationActiveDelegates.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationTopDelegates.request({ organizationId }));
    }, [organizationId]);

    const generatedOrganizationTopDelegates = organizationTopDelegates.data.map((otd) => {
        return {
            ...otd,
            delegateAddress: {
                title: otd.delegateAddress,
                link: LinkUtils.createDynamicLink(otd.delegateAddress, 'user'),
            },
            ensName: {
                title: otd.ensName,
                link: LinkUtils.createDynamicLink(otd.delegateAddress, 'user'),
            },
        };
    });

    return (
        <div className={styles.container}>
            {isTabBlocked ? (
                <div className={styles.premiumWindow}>
                    <PremiumTab title="Delegation Insights is a DeepDAO premium tab for this organization" />
                </div>
            ) : (
                <div className={styles.tablesWrapper}>
                    <CommonTable
                        title="Active Delegates"
                        infoText="Active Delegates Table."
                        lastUpdate={organizationActiveDelegates.lastUpdate}
                        tableRowsData={organizationActiveDelegates.data}
                        loading={organizationActiveDelegates.loading}
                        tableHeaderData={organizationActiveDelegatesTableHeaders}
                        sortedParamName={ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS.NUMBER_DELEGATES}
                    />

                    <CommonTable
                        title="Top Delegates"
                        infoText="Top Delegates Table."
                        lastUpdate={organizationActiveDelegates.lastUpdate}
                        tableRowsData={generatedOrganizationTopDelegates as any}
                        loading={organizationTopDelegates.loading}
                        tableHeaderData={organizationTopDelegatesTableHeaders}
                        sortedParamName={ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.NUMBER_OF_DELEGATORS}
                    />
                </div>
            )}
        </div>
    );
};
