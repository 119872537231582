import React, { FC, ReactElement, useContext } from 'react';

import {
    OrganizationFinance,
    OrganizationProposals,
    OrganizationMembers,
    OrganizationCoalitions,
    OrganizationResearch,
    TabsController,
} from './components';

import {
    FINANCE,
    PROPOSALS,
    MEMBERS,
    COALITIONS,
    RESEARCH,
    DELEGATION_INSIGHTS,
    VOTER_INSIGHTS,
} from 'constants/organizationTabs';

import { OrganizationContext } from 'context/organizationContext';
import { OrganizationDelegationInsights } from './components/OrganizationDelegationInsights';
import { OrganizationVoterInsights } from './components/OrganizationVoterInsights';

export const OrganizationData: FC = (): ReactElement => {
    const { organizationDataTabSelected } = useContext(OrganizationContext);

    const renderOrganizationDataComponent = () => {
        switch (organizationDataTabSelected) {
            case FINANCE:
                return <OrganizationFinance />;

            case PROPOSALS:
                return <OrganizationProposals />;

            case MEMBERS:
                return <OrganizationMembers />;

            case COALITIONS:
                return <OrganizationCoalitions />;

            case RESEARCH:
                return <OrganizationResearch />;

            case DELEGATION_INSIGHTS:
                return <OrganizationDelegationInsights />;

            case VOTER_INSIGHTS:
                return <OrganizationVoterInsights />;

            default:
                return <OrganizationFinance />;
        }
    };

    return (
        <>
            <TabsController />
            {renderOrganizationDataComponent()}
        </>
    );
};
