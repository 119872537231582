import React, { ReactElement, ReactNode } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, Loader, TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { generateUniqId } from 'helpers';
import { FormatUtils } from 'utils';
import { IOrganizationTrends } from '../../../../../../../../types/interfaces';

interface ITrendsTableProps {
    title: string;
    lastUpdate?: string | null;
    infoText?: string;
    tableHeaderData: any; //ICommonTableHeader<IOrganizationTrends, K>[];
    tableRowsData: IOrganizationTrends[];
    loading?: boolean;
    error?: string | null;
    headContent?: ReactNode;
}

export const TrendsTable = ({
    title,
    lastUpdate,
    infoText,
    tableHeaderData,
    tableRowsData,
    loading,
    headContent,
}: ITrendsTableProps): ReactElement => {
    const currentData = tableRowsData.find((t) => t.timePeriod === 'Current');
    const priorData = tableRowsData.find((t) => t.timePeriod === 'Prior');

    return (
        <div className={styles.content}>
            <div className={styles.headSection}>
                <div className={styles.textSection}>
                    <h4>{title}</h4>
                    <InfoCircle tooltipText={infoText} className={styles.infoIcon} />
                    {lastUpdate && <h5>Last Update: {FormatUtils.getFormattedDate(lastUpdate)}</h5>}
                </div>
                {headContent}
            </div>

            {tableRowsData.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <TableHeader tableHeaderData={tableHeaderData} />

                        <TableRow
                            key={generateUniqId()}
                            tableHeaderData={tableHeaderData}
                            rowItems={currentData}
                            priorData={priorData}
                        />
                        <TableRow key={generateUniqId()} tableHeaderData={tableHeaderData} rowItems={priorData} />
                    </div>
                </div>
            )}

            {loading && tableRowsData.length === 0 && <Loader size={40} className={styles.loaderBox} />}
            {tableRowsData.length === 0 && !loading && <TableDataNotFound forTable overflowMarginsSecondVariant />}
        </div>
    );
};
