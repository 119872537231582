import {
    COMMON_TABLE_FIELD_TYPES,
    ICommonTableHeader,
    ICommonTableHeaderSettings,
} from '../../../../../../CommonTable/types';
import {
    IActiveDelegatesMinimumDelegators,
    IActiveDelegatesMinimumVotes,
    IActiveDelegatesMinimumVotingPower,
    IActiveDelegatesNumberDelegates,
    IActiveDelegatesNumberDelegatesWithVoteThisYear,
    IOrganizationActiveDelegates,
} from '../../../../../../../types/interfaces';

export enum ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS {
    MINIMUM_VOTES = 'minimumVotes',
    MINIMUM_DELEGATORS = 'minimumDelegators',
    MINIMUM_VOTING_POWER = 'minimumVotingPower',
    NUMBER_DELEGATES = 'numberDelegates',
    NUMBER_DELEGATES_WITH_VOTE_THIS_YEAR = 'numberDelegatesWithVoteThisYear',
}

const tableSettingsFiveItems: ICommonTableHeaderSettings = {
    width: {
        desktop: '20%',
    },
};

const MINIMUM_VOTES: ICommonTableHeader<IActiveDelegatesMinimumVotes, ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS> = {
    title: 'Minimum votes',
    rowFieldKey: 'minimumVotes',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFiveItems,
};

const MINIMUM_DELEGATORS: ICommonTableHeader<
    IActiveDelegatesMinimumDelegators,
    ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS
> = {
    title: 'Minimum delegators',
    rowFieldKey: 'minimumDelegators',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFiveItems,
};

const MINIMUM_VOTING_POWER: ICommonTableHeader<
    IActiveDelegatesMinimumVotingPower,
    ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS
> = {
    title: 'Minimum voting power',
    rowFieldKey: 'minimumVotingPower',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFiveItems,
};

const NUMBER_DELEGATES: ICommonTableHeader<
    IActiveDelegatesNumberDelegates,
    ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS
> = {
    title: 'Number delegates',
    rowFieldKey: 'numberDelegates',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFiveItems,
};

const NUMBER_DELEGATES_WITH_VOTE_THIS_YEAR: ICommonTableHeader<
    IActiveDelegatesNumberDelegatesWithVoteThisYear,
    ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS
> = {
    title: 'Number delegates with vote this year',
    rowFieldKey: 'numberDelegatesWithVoteThisYear',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFiveItems,
};

const addSettingsToTableHeaderData = <T>(
    data: ICommonTableHeader<T, ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS>[],
    settings: ICommonTableHeaderSettings,
): ICommonTableHeader<T, ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS>[] =>
    data.map((item) => ({
        ...item,
        settings,
    }));

const organizationActiveDelegatesTableHeaders: ICommonTableHeader<
    IOrganizationActiveDelegates,
    ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS
>[] = addSettingsToTableHeaderData(
    [
        MINIMUM_VOTES,
        MINIMUM_DELEGATORS,
        MINIMUM_VOTING_POWER,
        NUMBER_DELEGATES,
        NUMBER_DELEGATES_WITH_VOTE_THIS_YEAR,
    ] as ICommonTableHeader<IOrganizationActiveDelegates, ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS>[],
    tableSettingsFiveItems,
);

export { organizationActiveDelegatesTableHeaders };
