import { Reducer } from '@reduxjs/toolkit';
import { OrganizationTabsActions } from 'store/actions';
import { IOrganizationTabsState } from '../../types/interfaces';
import { IOrganizationTabActionTypes } from '../../types/interfaces';

const defaultState: IOrganizationTabsState = {
    error: undefined,
    organizationTabs: {
        data: [],
        loading: false,
        lastUpdate: null,
    },
    organizationActiveDelegates: {
        data: [],
        loading: true,
        lastUpdate: null,
    },
    organizationTopDelegates: {
        data: [],
        loading: true,
        lastUpdate: null,
    },
    organizationProposalsTrends: {
        data: [],
        loading: true,
        lastUpdate: null,
    },
    organizationSuccessfulProposalsTrends: {
        data: [],
        loading: true,
        lastUpdate: null,
    },
    organizationVotesTrends: {
        data: [],
        loading: true,
        lastUpdate: null,
    },
    organizationVotersTrends: {
        data: [],
        loading: true,
        lastUpdate: null,
    },
    organizationAverageVotesPerProposalTrends: {
        data: [],
        loading: true,
        lastUpdate: null,
    },
};

export const organizationTabsReducer: Reducer<IOrganizationTabsState, IOrganizationTabActionTypes> = (
    state: IOrganizationTabsState = defaultState,
    action: IOrganizationTabActionTypes,
): IOrganizationTabsState => {
    const { type, payload } = <IOrganizationTabActionTypes>action;

    switch (type) {
        // general
        case OrganizationTabsActions.GET_ORGANIZATION_TABS_SUCCESS: {
            const { organizationTabs } = payload as any;

            return {
                ...state,
                organizationTabs: {
                    data: organizationTabs,
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_TABS_FAILURE: {
            return {
                ...state,
                organizationTabs: {
                    data: [],
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        // delegation insights tab
        case OrganizationTabsActions.GET_ORGANIZATION_ACTIVE_DELEGATES: {
            return {
                ...state,
                organizationActiveDelegates: {
                    data: [],
                    loading: true,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_ACTIVE_DELEGATES_SUCCESS: {
            const { organizationActiveDelegates, lastUpdate } = payload as any;

            return {
                ...state,
                organizationActiveDelegates: {
                    data: organizationActiveDelegates,
                    loading: false,
                    lastUpdate,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_ACTIVE_DELEGATES_FAILURE: {
            return {
                ...state,
                error: (action as any)?.error,
                organizationActiveDelegates: {
                    data: [],
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_TOP_DELEGATES: {
            return {
                ...state,
                organizationTopDelegates: {
                    data: [],
                    loading: true,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_TOP_DELEGATES_SUCCESS: {
            const { organizationTopDelegates, lastUpdate } = payload as any;

            return {
                ...state,
                organizationTopDelegates: {
                    data: organizationTopDelegates,
                    loading: false,
                    lastUpdate,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_TOP_DELEGATES_FAILURE: {
            return {
                ...state,
                error: (action as any)?.error,
                organizationTopDelegates: {
                    data: [],
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        // voters insights tab
        case OrganizationTabsActions.GET_ORGANIZATION_PROPOSALS_TRENDS: {
            return {
                ...state,
                organizationProposalsTrends: {
                    data: [],
                    loading: true,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_PROPOSALS_TRENDS_SUCCESS: {
            const { organizationProposalsTrends, lastUpdate } = payload as any;

            return {
                ...state,
                organizationProposalsTrends: {
                    data: organizationProposalsTrends,
                    loading: false,
                    lastUpdate,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_PROPOSALS_TRENDS_FAILURE: {
            return {
                ...state,
                error: (action as any)?.error,
                organizationProposalsTrends: {
                    data: [],
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS: {
            return {
                ...state,
                organizationSuccessfulProposalsTrends: {
                    data: [],
                    loading: true,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_SUCCESS: {
            const { organizationSuccessfulProposalsTrends, lastUpdate } = payload as any;

            return {
                ...state,
                organizationSuccessfulProposalsTrends: {
                    data: organizationSuccessfulProposalsTrends,
                    loading: false,
                    lastUpdate,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_FAILURE: {
            return {
                ...state,
                error: (action as any)?.error,
                organizationSuccessfulProposalsTrends: {
                    data: [],
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_VOTES_TRENDS: {
            return {
                ...state,
                organizationVotesTrends: {
                    data: [],
                    loading: true,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_VOTES_TRENDS_SUCCESS: {
            const { organizationVotesTrends, lastUpdate } = payload as any;

            return {
                ...state,
                organizationVotesTrends: {
                    data: organizationVotesTrends,
                    loading: false,
                    lastUpdate,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_VOTES_TRENDS_FAILURE: {
            return {
                ...state,
                error: (action as any)?.error,
                organizationVotesTrends: {
                    data: [],
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_VOTERS_TRENDS: {
            return {
                ...state,
                organizationVotersTrends: {
                    data: [],
                    loading: true,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_VOTERS_TRENDS_SUCCESS: {
            const { organizationVotersTrends, lastUpdate } = payload as any;

            return {
                ...state,
                organizationVotersTrends: {
                    data: organizationVotersTrends,
                    loading: false,
                    lastUpdate,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_VOTERS_TRENDS_FAILURE: {
            return {
                ...state,
                error: (action as any)?.error,
                organizationVotersTrends: {
                    data: [],
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS: {
            return {
                ...state,
                organizationAverageVotesPerProposalTrends: {
                    data: [],
                    loading: true,
                    lastUpdate: null,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_SUCCESS: {
            const { organizationAverageVotesPerProposalTrends, lastUpdate } = payload as any;

            return {
                ...state,
                organizationAverageVotesPerProposalTrends: {
                    data: organizationAverageVotesPerProposalTrends,
                    loading: false,
                    lastUpdate,
                },
            };
        }

        case OrganizationTabsActions.GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_FAILURE: {
            return {
                ...state,
                error: (action as any)?.error,
                organizationAverageVotesPerProposalTrends: {
                    data: [],
                    loading: false,
                    lastUpdate: null,
                },
            };
        }

        default: {
            return state;
        }
    }
};
