import { createApiAction } from 'helpers';

// general
export const GET_ORGANIZATION_TABS = 'GET_ORGANIZATION_TABS';
export const GET_ORGANIZATION_TABS_SUCCESS = 'GET_ORGANIZATION_TABS_SUCCESS';
export const GET_ORGANIZATION_TABS_FAILURE = 'GET_ORGANIZATION_TABS_FAILURE';

export const getOrganizationTabs = createApiAction(GET_ORGANIZATION_TABS);

// delegation insights tab
export const GET_ORGANIZATION_ACTIVE_DELEGATES = 'GET_ORGANIZATION_ACTIVE_DELEGATES';
export const GET_ORGANIZATION_ACTIVE_DELEGATES_SUCCESS = 'GET_ORGANIZATION_ACTIVE_DELEGATES_SUCCESS';
export const GET_ORGANIZATION_ACTIVE_DELEGATES_FAILURE = 'GET_ORGANIZATION_ACTIVE_DELEGATES_FAILURE';

export const GET_ORGANIZATION_TOP_DELEGATES = 'GET_ORGANIZATION_TOP_DELEGATES';
export const GET_ORGANIZATION_TOP_DELEGATES_SUCCESS = 'GET_ORGANIZATION_TOP_DELEGATES_SUCCESS';
export const GET_ORGANIZATION_TOP_DELEGATES_FAILURE = 'GET_ORGANIZATION_TOP_DELEGATES_FAILURE';

export const getOrganizationActiveDelegates = createApiAction(GET_ORGANIZATION_ACTIVE_DELEGATES);
export const getOrganizationTopDelegates = createApiAction(GET_ORGANIZATION_TOP_DELEGATES);

// voters insights
export const GET_ORGANIZATION_PROPOSALS_TRENDS = 'GET_ORGANIZATION_PROPOSALS_TRENDS';
export const GET_ORGANIZATION_PROPOSALS_TRENDS_SUCCESS = 'GET_ORGANIZATION_PROPOSALS_TRENDS_SUCCESS';
export const GET_ORGANIZATION_PROPOSALS_TRENDS_FAILURE = 'GET_ORGANIZATION_PROPOSALS_TRENDS_FAILURE';

export const GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS = 'GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS';
export const GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_SUCCESS = 'GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_SUCCESS';
export const GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_FAILURE = 'GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_FAILURE';

export const GET_ORGANIZATION_VOTES_TRENDS = 'GET_ORGANIZATION_VOTES_TRENDS';
export const GET_ORGANIZATION_VOTES_TRENDS_SUCCESS = 'GET_ORGANIZATION_VOTES_TRENDS_SUCCESS';
export const GET_ORGANIZATION_VOTES_TRENDS_FAILURE = 'GET_ORGANIZATION_VOTES_TRENDS_FAILURE';

export const GET_ORGANIZATION_VOTERS_TRENDS = 'GET_ORGANIZATION_VOTERS_TRENDS';
export const GET_ORGANIZATION_VOTERS_TRENDS_SUCCESS = 'GET_ORGANIZATION_VOTERS_TRENDS_SUCCESS';
export const GET_ORGANIZATION_VOTERS_TRENDS_FAILURE = 'GET_ORGANIZATION_VOTERS_TRENDS_FAILURE';

export const GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS = 'GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS';
export const GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_SUCCESS = 'GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_SUCCESS';
export const GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_FAILURE = 'GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_FAILURE';

export const getOrganizationProposalsTrends = createApiAction(GET_ORGANIZATION_PROPOSALS_TRENDS);
export const getOrganizationSuccessfulProposalsTrends = createApiAction(GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS);
export const getOrganizationVotesTrends = createApiAction(GET_ORGANIZATION_VOTES_TRENDS);
export const getOrganizationVotersTrends = createApiAction(GET_ORGANIZATION_VOTERS_TRENDS);
export const getOrganizationAverageVotesPerProposalTrends = createApiAction(GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS);
