import React, { FC, ReactElement, useContext } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ADDITIONAL_ORGANIZATION_DATA_TABS, BASE_ORGANIZATION_DATA_TABS } from 'constants/organizationTabs';

import { OrganizationContext } from 'context/organizationContext';
import { useAppSelector } from '../../../../../../store';

export const TabsController: FC = (): ReactElement => {
    const { organizationDataTabSelected, changeOrganizationDataTab } = useContext(OrganizationContext);

    const { organizationTabs } = useAppSelector(({ organizationTabs }) => organizationTabs);

    const additionalTabs = Object.entries(ADDITIONAL_ORGANIZATION_DATA_TABS).filter(([tKey]) => {
        const organizationTabsName = organizationTabs.data.map((ot) => ot.tab.name.toLowerCase());

        return organizationTabsName.includes(tKey.toLowerCase());
    });

    const tabs = [...Object.entries(BASE_ORGANIZATION_DATA_TABS), ...additionalTabs];

    return (
        <div className={styles.tabsWrapper}>
            <div className={styles.tabsContainer}>
                {tabs.map(([tabKey, tabItem]) => (
                    <div
                        onClick={() => changeOrganizationDataTab(tabKey)}
                        className={classNames(styles.tabItem, {
                            [styles.activeTab]: tabKey === organizationDataTabSelected,
                        })}
                        key={tabKey}
                    >
                        <div className={styles.itemContent}>
                            <span>{tabItem}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
