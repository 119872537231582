import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { API_KEY } from 'constants/apis';

import {
    IOrganizationActiveDelegate,
    IOrganizationTab,
    IOrganizationTabResponse,
    IOrganizationTopDelegate,
    IOrganizationTrends,
} from '../../types/interfaces';

export const OrganizationTabsApi = {
    getOrganizationTabs: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTab[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTab[]>>(
            `organization/${API_KEY}/${organizationId}/tabs`,
        ),
    getOrganizationActiveDelegates: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationActiveDelegate[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationActiveDelegate[]>>(
            `organization/${API_KEY}/${organizationId}/active_delegates`,
        ),
    getOrganizationTopDelegates: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTopDelegate[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTopDelegate[]>>(
            `organization/${API_KEY}/${organizationId}/top_delegates`,
        ),
    getOrganizationProposalsTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/proposals`,
        ),
    getOrganizationSuccessfulProposalsTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/successful_proposals`,
        ),
    getOrganizationVotesTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/votes`,
        ),
    getOrganizationVotersTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/voters`,
        ),
    getOrganizationAverageVotesPerProposalTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/average_votes_per_proposal`,
        ),
};
