export const BASE_URL = 'https://stg-deepdao-server.deepdao.io/';
export const DAO_LOGOS_BUCKET_BASE_URL = 'https://uploads.deepdao.io/assets/dao/logo';
export const API_KEY = 'ksdf3ksa-937slj3';
export const BACKOFICCE_URL = 'https://backoffice-server-stage.deepdao.io/';
export const IPFS_LOGO_URL = 'ipfs://';
export const IPFS_MAIN_URL = 'https://ipfs.io/ipfs/';
export const WEBSOCKET_URL = 'wss://stg-deepdao-server-ws.deepdao.io';
export const STARTER_MONTHLY_PLAN = "P-6BS2144712371352HMLNIC3I";
export const STARTER_YEARLY_PLAN = "P-6UL62020XN6601508MLNH4VA";
export const PRO_MONTHLY_PLAN = "P-26T83246P6153561JMLNIAAQ";
export const PRO_YEARLY_PLAN = "P-6L514284CT2018438MLNICFI";

export const DEEP_DAO_API = 'https://stg-deepdao-api.deepdao.io';
export const DEEP_DAO_API_PRO = 'https://stg-deepdao-api.deepdao.io/pro';