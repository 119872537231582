import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { API_KEY } from 'constants/apis';
import { getDeepDAOTokenFromStorage } from 'web3/storageHelper';

import {
    IOrganizationAssets,
    IOrganizationDecisionsData,
    IOrganizationInfoData,
    IOrganizationSimilarDao,
    IOrganizationActivityData,
    IOrganizationCurrenciesData,
    IOrganizationMembersData,
    ICoalitionsData,
    IOrganizationTopShareholdersData,
    IAumGraphData,
    IOrganizationDaosData,
    IMainCountersData,
    IDiscussionItems,
    ICreateDiscussionElement,
    IDicussionComment,
    IGetOrganizationBalanceData,
    IOrganizationFeatureItems,
    IProjectToken,
    IOrganizationTool,
    IOrganizationSubstorm,
    IFollowingResponse,
    IOrganizationTab,
} from 'types/interfaces';

export const OrganizationApi = {
    getOrganizationData: (organizationId: string): Promise<AxiosResponse<IOrganizationInfoData>> =>
        axiosInstance.get<IOrganizationInfoData>(`organization/${API_KEY}/${organizationId}`),
    getOrganizationSimilarDaos: (organizationId: string): Promise<AxiosResponse<IOrganizationSimilarDao[]>> =>
        axiosInstance.get<IOrganizationSimilarDao[]>(
            `organization/${API_KEY}/${organizationId}/organization_mutual_voters`,
        ),
    getOrganizationAssets: (organizationId: string): Promise<AxiosResponse<IOrganizationAssets>> =>
        axiosInstance.get<IOrganizationAssets>(`organization/${API_KEY}/${organizationId}/assets`),
    getOrganizationDecisions: (daoId: string): Promise<AxiosResponse<IOrganizationDecisionsData>> =>
        axiosInstance.get<IOrganizationDecisionsData>(`organization/${API_KEY}/${daoId}/governance/decisions`),
    getOrganizationActivity: (organizationId: string): Promise<AxiosResponse<IOrganizationActivityData>> =>
        axiosInstance.get<IOrganizationActivityData>(`organization/${API_KEY}/${organizationId}/governance/activity`),
    getOrganizationCurrencies: (organizationId: string): Promise<AxiosResponse<IOrganizationCurrenciesData>> =>
        axiosInstance.get<IOrganizationCurrenciesData>(`organization/${API_KEY}/${organizationId}/currencies`),
    getOrganizationMainInfo: (): Promise<AxiosResponse<IMainCountersData>> =>
        axiosInstance.get<IMainCountersData>(`organization/${API_KEY}/last-update`),
    getOrganizationProjectToken: (organizationId: string): Promise<AxiosResponse<IProjectToken>> =>
        axiosInstance.get<IProjectToken>(`discussion/${organizationId}/projectToken`),
    getOrganizationTools: (): Promise<AxiosResponse<IOrganizationTool[]>> =>
        axiosInstance.get<IOrganizationTool[]>(`organization/${API_KEY}/tools`),
    getOrganizationMembers: (daoId: string): Promise<AxiosResponse<IOrganizationMembersData>> =>
        axiosInstance.get<IOrganizationMembersData>(`organization/${API_KEY}/${daoId}/members`),
    getOrganizationCoalitions: (organizationId: string): Promise<AxiosResponse<ICoalitionsData>> =>
        axiosInstance.get<ICoalitionsData>(
            `/organization/${API_KEY}/${organizationId}/calculated-coalitions?membersInGroup=2`,
        ),
    getOrganizationTopShareholdersByHoldings: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTopShareholdersData>> =>
        axiosInstance.get<IOrganizationTopShareholdersData>(
            `organization/${API_KEY}/${organizationId}/top-shareholders`,
        ),
    getOrganizationTopActiveShareholders: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTopShareholdersData>> =>
        axiosInstance.get<IOrganizationTopShareholdersData>(
            `organization/${API_KEY}/${organizationId}/top-shareholders-by-activity`,
        ),
    getOrganizationAumGraph: (organizationId: string): Promise<AxiosResponse<IAumGraphData>> =>
        axiosInstance.get<IAumGraphData>(`organization/${API_KEY}/${organizationId}/aum-graph`),
    getOrganizationDao: (organizationId: string): Promise<AxiosResponse<IOrganizationDaosData>> =>
        axiosInstance.get<IOrganizationDaosData>(`organization/${API_KEY}/${organizationId}/dao`),
    getOrganizationDiscussions: ({
        organizationId,
        discussionsFilters,
        address,
    }: {
        organizationId: string;
        discussionsFilters: string;
        address?: string;
    }): Promise<AxiosResponse<IDiscussionItems[]>> =>
        axiosInstance.get<IDiscussionItems[]>(
            `discussion/${organizationId}${discussionsFilters}${address ? `&address=${address}` : ''}`,
        ),
    createDiscussion: ({
        organizationId,
        data,
    }: {
        organizationId: string;
        data: ICreateDiscussionElement;
    }): Promise<AxiosResponse<IDiscussionItems>> =>
        axiosInstance.post<IDiscussionItems>(`discussion/${organizationId}`, data, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    createDiscussionVote: ({
        id,
        organizationId,
        data,
    }: {
        id: string;
        organizationId: string;
        data: ICreateDiscussionElement;
    }): Promise<AxiosResponse<IDiscussionItems>> =>
        axiosInstance.post<IDiscussionItems>(`discussion/vote/${organizationId}/${id}`, data, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    createDiscussionComment: ({
        id,
        organizationId,
        data,
    }: {
        id: string;
        organizationId: string;
        data: ICreateDiscussionElement;
    }): Promise<AxiosResponse<IDicussionComment>> =>
        axiosInstance.post<IDicussionComment>(`discussion/comment/${organizationId}/${id}`, data, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getDiscussionComments: (id: string): Promise<AxiosResponse<IDicussionComment[]>> =>
        axiosInstance.get<IDicussionComment[]>(`discussion/comment/${id}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getOrganizationBalance: (organizationId: string): Promise<AxiosResponse<IGetOrganizationBalanceData>> =>
        axiosInstance.get<IGetOrganizationBalanceData>(`discussion/get-balance/${organizationId}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getOrganizationFeatures: (organizationId: string): Promise<AxiosResponse<IOrganizationFeatureItems>> =>
        axiosInstance.get<IOrganizationFeatureItems>(
            `organization/${API_KEY}/features?organizationId=${organizationId}`,
        ),
    getUserOrganizationRole: (organizationId: string): Promise<AxiosResponse<any>> =>
        axiosInstance.get<any>(`/self-listing/${organizationId}/user-organization-role`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getOrganizationDiscussionsMembers: ({
        organizationId,
        search,
    }: {
        [key: string]: string;
    }): Promise<AxiosResponse<any>> =>
        axiosInstance.get<any>(`/discussion/${organizationId}/members?limit=10${search ? '&search=' + search : ''}`),
    banDiscussionElement: ({ organizationId, elementId, dataForBan }: any): Promise<AxiosResponse<any>> =>
        axiosInstance.put<any>(`/discussion/${organizationId}?id=${elementId}`, dataForBan, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getOrganizationDiscussion: (
        organizationId: string,
        discussionId: string,
        address?: string,
    ): Promise<AxiosResponse<IDiscussionItems>> =>
        axiosInstance.get<IDiscussionItems>(
            `discussion/${organizationId}/discussion?discussionId=${discussionId}${
                address ? `&address=${address}` : ''
            }`,
        ),
    updateOrganizationDiscussion: (
        organizationId: string,
        discussionId: string,
        data: any,
    ): Promise<AxiosResponse<IDiscussionItems>> =>
        axiosInstance.patch<IDiscussionItems>(
            `discussion/${organizationId}/discussion?discussionId=${discussionId}`,
            data,
            { headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` } },
        ),
    getOrganizationSubstorms: (organizationId: string): Promise<AxiosResponse<IOrganizationSubstorm[]>> =>
        axiosInstance.get<IOrganizationSubstorm[]>(`discussion/${organizationId}/substorms`),
    getOrganizationIsFollowingData: (organizationId: string): Promise<AxiosResponse<IFollowingResponse>> =>
        axiosInstance.get<IFollowingResponse>(`social-features/following-organization/${organizationId}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    followOrganization: (organizationId: string): Promise<AxiosResponse<IFollowingResponse>> =>
        axiosInstance.post<IFollowingResponse>(`social-features/follows-organization/${organizationId}`, null, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    unFollowOrganization: (organizationId: string): Promise<AxiosResponse<IFollowingResponse>> =>
        axiosInstance.delete<IFollowingResponse>(`social-features/unfollows-organization/${organizationId}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getOrganizationTabs: (organizationId: string): Promise<AxiosResponse<IOrganizationTab[]>> =>
        axiosInstance.get<IOrganizationTab[]>(`organization/${API_KEY}/${organizationId}/tabs`),
    getOrganizationActiveDelegates: (organizationId: string): Promise<AxiosResponse<any[]>> =>
        axiosInstance.get<any>(`organization/${API_KEY}/${organizationId}/active_delegates`),
    getOrganizationTopDelegates: (organizationId: string): Promise<AxiosResponse<any[]>> =>
        axiosInstance.get<any>(`organization/${API_KEY}/${organizationId}/top_delegates`),
};
