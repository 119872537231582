import React, { FC, ReactElement, useEffect } from 'react';

import styles from './styles.module.scss';

import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { DELEGATION_INSIGHTS } from '../../../../../../constants/organizationTabs';
import { PremiumTab } from '../../../../../PremiumTab';
import { OrganizationTabsActions } from 'store/actions';
import { ProposalsVotesGraph } from './components/ProposalsVotesGraph';
import { VotingActivity } from './components/VotingActivity';
import { organizationTrendsTableHeaders } from './constants/organization-trends.constants';
import { TrendsTable } from './components/TrendsTable';

export const OrganizationVoterInsights: FC = (): ReactElement => {
    const {
        organizationTabs,
        organizationProposalsTrends,
        organizationSuccessfulProposalsTrends,
        organizationVotesTrends,
        organizationVotersTrends,
        organizationAverageVotesPerProposalTrends,
    } = useAppSelector(({ organizationTabs }) => organizationTabs);
    const isTabBlocked = organizationTabs.data.find((t) => t.tab.name === DELEGATION_INSIGHTS)?.isBlocked;
    const { organizationData } = useAppSelector(({ organization }) => organization);
    const organizationId = organizationData.id;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(OrganizationTabsActions.getOrganizationProposalsTrends.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationSuccessfulProposalsTrends.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationVotesTrends.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationVotersTrends.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationAverageVotesPerProposalTrends.request({ organizationId }));
    }, [organizationId]);

    console.log(organizationProposalsTrends.loading);

    return (
        <div className={styles.container}>
            {isTabBlocked ? (
                <div className={styles.premiumWindow}>
                    <PremiumTab title="Voter Insights is a DeepDAO premium tab for this organization" />
                </div>
            ) : (
                <div>
                    <div className={styles.content}>
                        <div className={styles.mainInfoContent}>
                            <ProposalsVotesGraph />
                            <VotingActivity />
                        </div>
                        <div className={styles.tablesWrapper}>
                            <TrendsTable
                                title="Proposals"
                                infoText="Proposals Table."
                                lastUpdate={organizationProposalsTrends.lastUpdate}
                                tableRowsData={organizationProposalsTrends.data}
                                loading={organizationProposalsTrends.loading}
                                tableHeaderData={organizationTrendsTableHeaders}
                            />

                            <TrendsTable
                                title="Successful Proposals"
                                infoText="Successful Proposals Table."
                                lastUpdate={organizationSuccessfulProposalsTrends.lastUpdate}
                                tableRowsData={organizationSuccessfulProposalsTrends.data}
                                loading={organizationSuccessfulProposalsTrends.loading}
                                tableHeaderData={organizationTrendsTableHeaders}
                            />

                            <TrendsTable
                                title="Votes"
                                infoText="Votes Table."
                                lastUpdate={organizationVotesTrends.lastUpdate}
                                tableRowsData={organizationVotesTrends.data}
                                loading={organizationVotesTrends.loading}
                                tableHeaderData={organizationTrendsTableHeaders}
                            />

                            <TrendsTable
                                title="Voters"
                                infoText="Voters Table."
                                lastUpdate={organizationVotersTrends.lastUpdate}
                                tableRowsData={organizationVotersTrends.data}
                                loading={organizationVotersTrends.loading}
                                tableHeaderData={organizationTrendsTableHeaders}
                            />

                            <TrendsTable
                                title="Average Votes per Proposal"
                                infoText="Average Votes per Proposal Table."
                                lastUpdate={organizationAverageVotesPerProposalTrends.lastUpdate}
                                tableRowsData={organizationAverageVotesPerProposalTrends.data}
                                loading={organizationAverageVotesPerProposalTrends.loading}
                                tableHeaderData={organizationTrendsTableHeaders}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
