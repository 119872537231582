import { ITabs_Types } from 'types/interfaces';

export const FINANCE = 'finance';
export const PROPOSALS = 'proposals';
export const MEMBERS = 'members';
export const COALITIONS = 'coalitions';
export const RESEARCH = 'dig_deeper';
export const DELEGATION_INSIGHTS = 'delegation insights';
export const VOTER_INSIGHTS = 'voter insights';

const RESEARCH_NAME = 'dig deeper';

export const BASE_ORGANIZATION_DATA_TABS: ITabs_Types = {
    [FINANCE]: FINANCE,
    [PROPOSALS]: PROPOSALS,
    [MEMBERS]: MEMBERS,
    [COALITIONS]: COALITIONS,
    [RESEARCH]: RESEARCH_NAME,
    [VOTER_INSIGHTS]: VOTER_INSIGHTS,
};

export const ADDITIONAL_ORGANIZATION_DATA_TABS: ITabs_Types = {
    [DELEGATION_INSIGHTS]: DELEGATION_INSIGHTS,
};

export const ORGANIZATION_DATA = 'organization_data';
export const DISCUSSIONS = 'brainstorm';
const ORGANIZATION_DATA_NAME = 'organization data';

export const ORGANIZATION_TABS: ITabs_Types = {
    [ORGANIZATION_DATA]: ORGANIZATION_DATA_NAME,
    [DISCUSSIONS]: DISCUSSIONS,
};
